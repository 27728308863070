import React, { FC, useState } from "react";
import PostCardSaveAction from "components/PostCardSaveAction/PostCardSaveAction";
import { EmissionTypeInfos, PostDataType, RadioType } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import ProgramPostCardMeta from "components/PostCardMeta/ProgramPostCardMeta";
import RadioFeaturedMedia from "components/PostFeaturedMedia/RadioFeaturedMedia";
import ProgramPostFeaturedMedia from "components/PostFeaturedMedia/ProgramPostFeaturedMedia";
import Link from "components/Link";
import ProgramPostFeaturedMediaLive from "components/PostFeaturedMedia/ProgramPostFeaturedMediaLive";
import RadioFeaturedMediaLive from "components/PostFeaturedMedia/RadioFeaturedMediaLive";

export interface ProgramLiveCardProps {
  className?: string;
  post?: EmissionTypeInfos | any;
  radio?: EmissionTypeInfos | any;
  ratio?: string;
  hiddenAuthor?: boolean;
}



const ProgramLiveCard: FC<ProgramLiveCardProps> = ({
  className = "h-full",
  post,
  radio,
  hiddenAuthor = false,
  ratio = "aspect-w-4 aspect-h-3",
}) => {
  const title = post ? post.title : radio?.title;
  console.log(title);
  const created_at =post ? post.diffusion_date : radio?.diffusion_date;
  console.log(created_at);
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-neutral-900 ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      //
    >
      <div
        className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
      >
        <div>
          {
            post ? 
            <ProgramPostFeaturedMediaLive post={post} isHover={isHover} />
            : 
            radio ? <RadioFeaturedMediaLive radio={radio} isHover={isHover} /> : null
          }
        </div>
      </div>
      {/* <Link href={href} className="absolute inset-0"></Link> */}
      {/* <span className="absolute top-3 inset-x-3 z-10">
        <CategoryBadgeList categories={categories} />
      </span> */}

      <div className="p-4 flex flex-col space-y-3">
        {!hiddenAuthor ? null : (
          post ?
          <span className="text-xs text-neutral-500">{new Date(created_at.toString()).toLocaleString("fr-FR", {timeZone : "Africa/Porto-Novo"})}</span>
          : null
          )}
        <h3 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <span className="line-clamp-2" title={title}>
            {title}
          </span>
        </h3>
        <div className="flex items-end justify-between mt-auto">
        <small className="text-xs text-neutral-900">{new Date(created_at.toString()).toLocaleString("fr-FR", {timeZone : "Africa/Porto-Novo"})}</small>
          {/* <PostCardSaveAction className="relative" /> */}
        </div>
      </div>
    </div>
  );
};

export default ProgramLiveCard;
