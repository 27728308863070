import React, { useEffect, useState } from "react";
import { FiEye, FiEdit2  } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import NcImage from "components/NcImage/NcImage";
import Pagination from "components/Pagination/Pagination";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import apiInstanceAwr from "app/api/apiServicesAwr";
import { RadioType } from "data/types";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";
import { useStore } from "../store";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfos } from "utils/localStorageService";
import { Hourglass } from "react-loader-spinner";

const DashboardLive = () => {
 const navigate = useNavigate();
  const [emissions, setEmissions] = useState<RadioType[]>([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const channelId: any = getUserInfos()?.channel?.id;
  //console.log(channelId)
  useEffect(() => {
    if (channelId) {
      apiInstanceAws
        .get<{ allLive: RadioType[] }>(`emission/live/${channelId}`)
        .then((response) => {
          //console.log(response.data.allLive)
          setEmissions(response.data.allLive);
        })
        .catch((error) => {
          console.log("Erreur lors de la récupération des émissions :", error);
        });
    }
  }, [channelId]);  

  const handleDelete = async (emissionId: any) => {
    try {
      const userConfirmed = window.confirm('Êtes-vous sûr de vouloir supprimer définitivement cet élément ?');
      if (userConfirmed) {
          setLoading(true);
          await apiInstanceAws.delete(`emission/delete/${emissionId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              // Ajoutez d'autres en-têtes d'authentification si nécessaire
            },
        });
        setEmissions((prevEmissions) => prevEmissions.filter((e) => e.id !== emissionId));
        console.log("suppression")
      }
    } catch (error) {
      console.error('Erreur lors de la suppression : ', error);
    }finally {
      setLoading(false);
      navigate('/dashboard/live')
    }
  };

  return (
    <Layout>
       {loading && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={['#306cce', '#72a1ed']}
            />
          </div>
        )}
      <LayoutDashboard>
        <div className="flex flex-col space-y-8">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
              <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
                  <thead className="bg-neutral-50 dark:bg-neutral-800">
                    <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                      <th scope="col" className="px-6 py-3">
                        Titre
                      </th>
                      <th scope="col" className="px-6 py-3">
                        Description
                      </th>
                      <th scope="col" className="px-6 py-3">
                      Date de Diffusion
                      </th>
                      <th scope="col" className="px-8 py-3">
                       Action
                      </th>
                      {/* <th scope="col" className="px-8 py-3">
                       Partage Youtube
                      </th>  */}
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                    {emissions?.map((item) => (
                       <tr key={item.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400" title={item.title}>
                          {item.description? ( 
                            <span> {item.title?.slice(0, 12) + '...'}</span>
                            ):(
                              <span> Pas de titre</span>
                            )
                          }
                        </td>
                       <td className="px-6 py-4 whitespace-nowrap">
                         {item.description ? (
                           <span className="px-2 inline-flex text-xs  text-neutral-500 dark:text-neutral-400 lg:text-sm"  title={item.description}>
                             {item.description.slice(0, 25) + '...'}
                           </span>
                         ) : (
                           <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                             Pas de description
                           </span>
                         )}
                       </td>
                      
                       <td className="px-6 py-4 whitespace-nowrap">
                         {item.diffusion_date ? (
                           <span className="px-2 inline-flex text-xs  text-neutral-500 dark:text-neutral-400 lg:text-sm">
                             {new Date(item.diffusion_date).toLocaleDateString()}
                           </span>
                         ) : (
                           <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                             date Non spécifié
                           </span>
                         )}
                       </td>
                       <td className="px-6 py-4 whitespace-nowrap flex">
                          <Link to={`/dashboard/detaillive-stream/${item.id}`} className="text-blue-600 hover:text-rose-900 mr-2"><FiEye  /></Link> 
                          <Link to={`/dashboard/edit-emission/${item.id}`} className="text-blue-600 hover:text-rose-900 mr-2"><FiEdit2/></Link>
                          <button onClick={() => handleDelete(item.id)} className="text-rose-600 hover:text-rose-900"><RiDeleteBin6Line /></button>
                       </td>
                       {/* <td className="px-6 py-4 whitespace-nowrap">
                         {item.diffusion_date ? (
                           <span className="px-2 inline-flex text-xs  text-neutral-500 dark:text-neutral-400 lg:text-sm">
                             {new Date(item.diffusion_date).toLocaleDateString()}
                           </span>
                         ) : (
                           <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                             date Non spécifié
                           </span>
                         )}
                       </td> */}
                     </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Pagination />
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardLive;
