import React, { FC, ReactNode } from "react";
import Heading from "components/Heading/Heading";
import { EmissionTypeInfos, RadioType } from "data/types";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ProgramCard from "components/ProgramCard/ProgramCard";
import ProgramLiveCard from "components/ProgramCard/ProgramLiveCard";


export interface SectionGridLivePostsProps {
  radiosList?: EmissionTypeInfos[] | null;
  className?: string;
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
}

const SectionGridLivePosts: FC<SectionGridLivePostsProps> = ({
  radiosList,
  className = "",
  gridClass = "",
  heading,
  subHeading,
  headingIsCenter,
}) => {
  const renderCard = (radio: EmissionTypeInfos) => {
    return <ProgramLiveCard key={radio.id} radio={radio} />;
  };

				return (
				<div className={`nc-SectionGridPosts relative ${className}`}>
								{/* <Heading desc={subHeading} isCenter={headingIsCenter}>
												{heading}
								</Heading> */}
								<div className={`grid gap-6 md:gap-8 ${gridClass}`}>
												{radiosList?.map((radio) => renderCard(radio))}
								</div>
								<div className="flex mt-20 justify-center items-center">
												<ButtonPrimary loading>Voir Plus</ButtonPrimary>
								</div>
				</div>
				);
				};

				export default SectionGridLivePosts;
