import React, { useEffect, useState } from "react";
import { FiEye, FiEdit2  } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import NcImage from "components/NcImage/NcImage";
import Pagination from "components/Pagination/Pagination";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import apiInstanceAwr from "app/api/apiServicesAwr";
import { RadioType } from "data/types";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";
import { useStore } from "../store";
import { Link, useNavigate } from "react-router-dom";

const DashboardPosts = () => {
  const { data, fetchData, deleteChannel  } = useStore();

  useEffect(() => {
    fetchData();
  }, []);


const handleDelete = (channelId : any) => {
    if (window.confirm("Voulez-vous vraiment supprimer cette chaîne ?")) {
      deleteChannel(channelId);
    }
  };
  return (
    <Layout>
      <LayoutDashboard>
        <div className="flex flex-col space-y-8">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
              <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
                  <thead className="bg-neutral-50 dark:bg-neutral-800">
                    <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                      <th scope="col" className="px-6 py-3">
                        Chaîne
                      </th>
                      <th scope="col" className="px-6 py-3">
                        nom de la radio
                      </th>
                      <th scope="col" className="px-6 py-3">
                      IP du serveur
                      </th>
                      <th scope="col" className="px-6 py-3">
                      Port
                      </th>
                      <th scope="col" className="px-6 py-3">
                       Action
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td className="px-6 py-4">
                          <div className="flex items-center w-96 lg:w-auto max-w-md overflow-hidden">
                            <NcImage
                              containerClassName="flex-shrink-0 h-12 w-12 rounded-lg relative z-0 overflow-hidden lg:h-14 lg:w-14"
                              src={item.radio_logo}
                              fill
                              sizes="80px"
                              alt="post"
                            />
                            {/* <div className="ml-4 flex-grow">
                              <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                                {item.auth_username}
                              </h2>
                            </div> */}
                          </div>
                        </td>
                        {/* <td className="px-6 py-4 whitespace-nowrap">
                          {item.radio_phone ? (
                            <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-teal-100 text-teal-900 lg:text-sm">
                              {item.phone}
                            </span>
                          ) : (
                            <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                              Pas de contact
                            </span>
                          )}
                        </td> */}
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-neutral-500 dark:text-neutral-400">
                          <span> {item.radio_name}</span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.ip_server_adress ? (
                            <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-teal-100 text-teal-900 lg:text-sm">
                              {item.ip_server_adress}
                            </span>
                          ) : (
                            <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                              Pas de IP
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {item.host_port ? (
                            <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-teal-100 text-teal-900 lg:text-sm">
                              {item.host_port}
                            </span>
                          ) : (
                            <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                              Pas de Port
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap flex">
                        <Link to={`/dashboard/edit-channel/${item.id}`} className="text-blue-600 hover:text-rose-900 mr-2"><FiEdit2/></Link> 
                          <Link to={`/dashboard/subscription/${item.id}`} className="text-blue-600 hover:text-rose-900 mr-2"><FiEye  /></Link>
                          <button onClick={() => handleDelete(item.id)} className="text-rose-600 hover:text-rose-900"><RiDeleteBin6Line /></button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Pagination />
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardPosts;
