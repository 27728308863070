import ButtonPrimary from "components/Button/ButtonPrimary";
import { Hourglass } from "react-loader-spinner";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Select from "components/Select/Select"
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import apiInstanceAwr from "app/api/apiServicesAwr";
import { RadioType } from "data/types";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";
import { useNavigate } from "react-router-dom";

const DashboardEditProfile = () => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<RadioType[]>([]);
  const [selectedOption, setSelectedOption] = useState<RadioType | null>(null);
  const [displayData, setDisplayData] = useState<RadioType | null>(null);
  const [formaData, setFormData]= useState<RadioType | {} | any>({
    radio_id : '',
    radio_name: '',
    radio_logo: '',
    radio_phone: '',
    radio_email: '',
    radio_city: '',
    ip_server_adress: '',
    stream_server_name: '',
    host_port: '',
    server_entrypoint: '',
    auth_username: '',
    auth_password: '',
    server_output: '',
  })
  const navigate = useNavigate();
  useEffect(() => {
    apiInstanceAws.get<RadioType[]>('/channel/list-radios')
      .then((response)=>{
        const data = response.data;
        setOptions(data);
      })
      .catch((error)=>{
        console.log("recuperation de la liste des radios non chaines:", error)
      })
  }, []);
    const handleChange = (event : React.ChangeEvent<HTMLSelectElement>) =>{
      const selectedValue = event.target.value;
      const selectedOptionData = options.find((option)=>option.id.toString() === selectedValue);
      console.log("Radio sélectionnée:", selectedOptionData);
      // Mettez à jour l'état selectedOption avec les données de l'option sélectionnée
      setSelectedOption(selectedOptionData || null);

    // Affichez les données sélectionnées
    setDisplayData(selectedOptionData || null);

    
    if (selectedOptionData) {
      setFormData({
        radio_id : selectedOptionData.id,
        radio_name: selectedOptionData.name,
        radio_logo: selectedOptionData.logo,
        radio_phone: selectedOptionData.phone,
        radio_email: selectedOptionData.email,
        radio_city: selectedOptionData.city,
        ip_server_adress: selectedOptionData.ip_server_adress,
        stream_server_name: selectedOptionData.stream_server_name,
        host_port: selectedOptionData.host_port,
        server_entrypoint: selectedOptionData.server_entrypoint,
        auth_username: selectedOptionData.auth_username,
        auth_password: selectedOptionData.auth_password,
        server_output: selectedOptionData.server_output,
      });
      
    } else {
      setFormData({
        radio_name: '',
        radio_phone: '',
        radio_email: '',
        radio_city: '',
        ip_server_adress: '',
        stream_server_name: '',
        host_port: '',
        server_entrypoint: '',
        auth_username: '',
        auth_password: '',
        server_output: '',
      });
    }
     
    };

    const handleFormaChange = (event : React.ChangeEvent<HTMLInputElement>)=>{
      const { name, value} = event.target;
      setFormData({
        ...formaData,
        [name]: value,
      })
    };
    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      //console.log('Données du formulaire soumises :', formaData);
      setLoading(true);
      apiInstanceAws.post<RadioType[]>('/channel/create', formaData)
      .then((response) => {
        setLoading(false);
        navigate('/dashboard/posts')
        console.log('Réponse de l\'API :', response.data +"url: " + window.location.href);
      })
      .catch((error) => {
        console.log('Erreur lors de l\'envoi du formulaire :', error);
      });
    };
  return (
    <Layout>
      <LayoutDashboard>
      {loading && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={['#306cce', '#72a1ed']}
            />
          </div>
        )}
        <div className="rounded-sm md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          <Label>Création d'une Chaîne</Label>
          <div>
            <Select className="my-10" value={selectedOption ? selectedOption.id.toString() : ''} onChange={handleChange}>
              <option value="">Sélectionnez la radio</option>
              {options.map((option) => (
                <option key={option.id.toString()} value={option.id.toString()}>
                  {option.name}
                </option>
              ))}
            </Select>
          </div>
          <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <Label>Nom de la radio</Label>
              <Input 
                placeholder="Nom de la radio" 
                type="text" 
                className="mt-1" 
                name="radio_name"
                value={formaData.radio_name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                disabled
              />
            </label>
            <label className="block">
              <Label>email</Label>
              <Input 
                  placeholder="email" 
                  type="text" 
                  className="mt-1"
                  name="radio_email"
                  value={formaData.radio_email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                  disabled 
                />
            </label>
            <label className="block">
              <Label>Phone</Label>
              <Input 
                placeholder="phone" 
                type="text" 
                className="mt-1"
                name="radio_phone"
                value={formaData.radio_phone}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                disabled 
                />
            </label>
            <label className="block">
              <Label>ville</Label>
              <Input 
                placeholder="ville" 
                type="text" 
                className="mt-1"
                name="radio_city" 
                value={formaData.radio_city}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                disabled
                />
            </label>
            <label className="block">
              <Label>Adresse IP du serveur</Label>
              <Input 
                placeholder="adresse IP du serveur" 
                type="text" 
                className="mt-1" 
                name="ip_server_adress"
                value={formaData.ip_server_adress}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>Port du serveur</Label>
              <Input 
                placeholder="Port du serveur" 
                type="text" 
                className="mt-1" 
                name="host_port"
                value={formaData.host_port}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>point de montage</Label>
              <Input 
                placeholder="point de montage" 
                type="text" 
                className="mt-1" 
                name="server_entrypoint"
                value={formaData.server_entrypoint}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>Url de diffusion</Label>
              <Input 
                placeholder="url de diffusion" 
                type="text" 
                className="mt-1"
                name="server_output"
                value={formaData.server_output}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>Nom d'utilisateur</Label>
              <Input 
                placeholder="nom d'utilisateur" 
                type="text" 
                className="mt-1"
                name="auth_username"
                value={formaData.auth_username} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>Mot de passe</Label>
              <Input 
                type="text" 
                className="mt-1"
                name="auth_password"
                value={formaData.auth_password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)} 
                />
            </label>
            <label className="block md:col-span-2">
              <Label> Nom du serveur</Label>
              <Input
                type="text"
                placeholder="nom du serveur"
                className="mt-1"
                name="stream_server_name"
                value={formaData.stream_server_name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
              />
            </label>
            <ButtonPrimary className="md:col-span-2" type="submit"  disabled={loading}>
              Créer la Chaîne
              {loading ? "En cours..." : "Créer la Chaîne"}
            </ButtonPrimary>
          </form>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardEditProfile;
