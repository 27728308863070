import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import NcPlayIcon from "components/NcPlayIcon/NcPlayIcon";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import isSafariBrowser from "utils/isSafariBrowser";
import Image from "components/Image";
import { DEMO_CATEGORIES } from "data/taxonomies";
import SingleTitle from "app/(singles)/SingleTitle";
import SingleMetaAction2 from "app/(singles)/SingleMetaAction2";
import Layout from "../layout";
import { useParams } from "react-router-dom";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";
import { RadioType } from "data/types";
import liveImage from "images/live.png";
// import loadingVideo from "loading/"
const PageVod = () => {
  // const {videoID} = useParams();
  const [isPlay, setIsPlay] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [liveinfo, setLiveInfo] = useState<RadioType | null>(null);
  const { videoID } = useParams<{ videoID: string }>();

  useEffect(() => {
    setIsRendered(true);
    apiInstanceAws.get<{emission : RadioType | null}>(`/emission/${videoID}`)
    .then((response)=>{
      const liveinfo = response.data.emission;
     // console.log(`http://localhost:8000/${liveinfo?.thumbnail}`)
      //console.log(`http://localhost:8000/${liveinfo?.files[0].path}`)
      console.log(liveinfo);
      setLiveInfo(liveinfo);
    })
    .catch((error)=>{
      console.log("Erreur de récuperation des information de la chaine:", error)
    })
  }, []);
  const imagePath = `${liveinfo?.thumbnail}`;
    console.log(liveinfo?.files[0].path)
    const videoPath = `${liveinfo?.files[0].path}`;
  const formattedImagePath = imagePath.replace(/\\/g, '/');
  // const formattedVideoPath = videoPath.replace(/\\/g, '/');
  // console.log(formattedImagePath, formattedVideoPath)
  const renderMainVideo = () => {

    return (
      <div className="">
        {isSafariBrowser() && !isPlay && (
          <div
            className="absolute inset-0 z-10 cursor-pointer "
            onClick={() => setIsPlay(true)}
          >
            <Image
              src={`${formattedImagePath}`}
              className="object-cover"
              sizes="(max-width: 1024px) 100vw, 50vw"
              alt="single"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <NcPlayIcon />
            </div>
          </div>
        )}
        {isRendered && (
          <ReactPlayer
             //url="https://71272ae81053.entrypoint.cloud.wowza.com/app-296k5Z49/ngrp:ca7b1c0d_all/playlist.m3u8"
             url={videoPath}
            className="absolute inset-0"
            playing={isSafariBrowser() ? isPlay : true}
            width="100%"
            height="100%"
            controls
            light={
              isSafariBrowser()
                ? false
                : `${formattedImagePath}` || "fallback_image_url"
            }
            playIcon={<NcPlayIcon />}
          />
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={`nc-SingleHeader `}>
        <div className="space-y-5 dark text-neutral-100">
          {/* <CategoryBadgeList
            itemClass="!px-3"
            categories={[DEMO_CATEGORIES[2]]}
          /> */}
          <SingleTitle
            mainClass="text-neutral-900 font-semibold text-3xl md:!leading-[120%] dark:text-neutral-100"
            title={`${liveinfo?.description}`} 
          />

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          {/* <div className="flex flex-col space-y-5">
            <PostMeta2
              size="large"
              className="leading-none flex-shrink-0"
              hiddenCategories
              avatarRounded="rounded-full shadow-inner"
            />
            <SingleMetaAction2 />
          </div> */}
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <header className="container relative py-14 lg:py-20 flex flex-col lg:flex-row lg:items-center">
        <div className="nc-PageSingleVideo__headerWrap absolute inset-y-0 transform translate-x-1/2 right-1/2 w-screen lg:translate-x-0 lg:w-[calc(100vw/2)] bg-neutral-900 dark:bg-black dark:bg-opacity-50 lg:rounded-r-[40px]"></div>
        <div className="pb-10 lg:pb-0 lg:pr-10 relative">{renderHeader()}</div>
        <div className="relative lg:w-8/12 flex-shrink-0">
              <div className="aspect-w-16 aspect-h-16 sm:aspect-h-9 border-4 border-neutral-300 dark:border-neutral-800 shadow-2xl bg-neutral-800 rounded-3xl overflow-hidden z-0">
                {renderMainVideo()}
              </div>
        </div>
      </header>
    </Layout>
  );
};

export default PageVod;
