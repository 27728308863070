import React, { FC, ReactNode, useState } from "react";
import Heading from "components/Heading/Heading";
import { RadioType } from "data/types";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ProgramCard from "components/ProgramCard/ProgramCard";


export interface SectionGridPostsProps {
  radiosList?: RadioType[];
  className?: string;
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
}

const SectionGridPosts: FC<SectionGridPostsProps> = ({
  radiosList,
  className = "",
  gridClass = "",
  heading,
  subHeading,
  headingIsCenter,
}) => {
  const [visibleRadios, setVisibleRadios] = useState(4);
  const renderCard = (radio: RadioType) => {
    return <ProgramCard key={radio.id} radio={radio} />;
  };
  const handleShowMore = () => {
    setVisibleRadios((prevVisibleRadios) => prevVisibleRadios + 4);
  };

				return (
				<div className={`nc-SectionGridPosts relative ${className}`}>
          <Heading desc={subHeading} isCenter={headingIsCenter}>
                  {heading}
          </Heading>
          <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
              {radiosList?.slice(0, visibleRadios).map((radio) => renderCard(radio))}
          </div>
          {radiosList && radiosList.length > visibleRadios && (
            <div className="flex mt-20 justify-center items-center">
              <ButtonPrimary onClick={handleShowMore} loading={false}>
                Voir plus
              </ButtonPrimary>
            </div>
          )}
				</div>
				);
				};

				export default SectionGridPosts;
