import { useNavigate } from "react-router-dom";
import {TokenDataType} from "data/types"
import { useState } from "react";

export const useAuth = ()=>{
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("token"));
  const login = (token: string)=>{
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
    window.location.href = '/dashboard';
    //navigate('/dashboard');
   // navigate('/dashboard')
  };

  const logout = ()=>{
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    window.location.href = '/';
    //navigate('/home-4');
    // navigate('/login')
  };

  const getUserRole = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken: TokenDataType = JSON.parse(atob(token.split(".")[1]));
      //console.log("dataaaaa " + decodedToken.role);
      return decodedToken.role;
    }
    return null;
  };
  const isUserAdmin = (userRole: string | null) => {
    return userRole === "ADMIN";
  };

  const isUserSpectator = (userRole: string | null) => {
    return userRole === "SPECTATOR";
  };

  const isUserAnimateur = (userRole: string | null) => {
    return userRole === "ANIMATOR";
  };

  const getChannelInfo = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken: TokenDataType = JSON.parse(atob(token.split(".")[1]));
      return decodedToken.channel;
    }
    return null;
  };

  return { login, logout, isAuthenticated, getUserRole, isUserAdmin, isUserSpectator, isUserAnimateur, getChannelInfo };

}
