
import {UserDataType} from "../data/types";
export const getUserInfos = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken: UserDataType = JSON.parse(atob(token.split(".")[1]));
      console.log("dataaaaa ", decodedToken);
      return decodedToken;
    }
    return null;
  };