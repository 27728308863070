import React, { FC } from "react";
import { EmissionTypeInfos, PostDataType, RadioType } from "../../data/types";
import GallerySlider from "./GallerySlider";
import MediaVideo from "./MediaVideo";
import PostTypeFeaturedIcon from "../PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import MediaAudio from "./MediaAudio";
import Image from "../Image";
import Link from "../Link";
import liveImage from "images/live.png"

export interface RadioFeaturedMediaLiveProps {
  className?: string;
  radio: EmissionTypeInfos;
  isHover?: boolean;
}

const RadioFeaturedMediaLive: FC<RadioFeaturedMediaLiveProps> = ({
  className = "w-full h-full",
  radio,
  isHover = false,
}) => {
  const { id, thumbnail } = radio;
  const postType = "video";

  const isPostMedia = () => postType === "video" ;

  return (
    <div className={`nc-RadioFeaturedMedia relative ${className}`}>
        <Image
          alt="Thumnail"
          fill
          className="object-cover"
          src={liveImage}
          sizes="(max-width: 600px) 480px, 800px"
        />
      
        <Link
          href={"/single-video/demo-slug/"+id}
          className={`block absolute inset-0 ${
            !postType
              ? "bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
              : ""
          }`}
        />
    </div>
  );
};

export default RadioFeaturedMediaLive;
