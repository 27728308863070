import React, { useState, useEffect } from "react";
import { Hourglass } from "react-loader-spinner";
import { useParams, useNavigate } from "react-router-dom";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Textarea from "components/Textarea/Textarea";
import Select from "components/Select/Select";
import Label from "components/Label/Label";
import Layout from "../../layout";
import liveRadioImage from "../../../../images/liveradio.png"
import LayoutDashboard from "../layout";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";

type FormData = {
  title: string;
  description: string;
  type: string;
  diffusion_date: string;
  channelId: any;
  thumbnail: File | null;
  video: File | null;
  [key: string]: string | null | any;
};

const DashboardEditVODEmission = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState<string>(""); // État pour la catégorie
  const [showFileInput, setShowFileInput] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    type: "",
    diffusion_date: "",
    channelId: null,
    thumbnail: null,
    video: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    apiInstanceAws
      .get<{ emission: FormData | null }>(`emission/${id}`)
      .then((response) => {
        const emissionData = response.data.emission;
        console.log(emissionData);
        if (emissionData) {
          const formattedDate = new Date(emissionData.diffusion_date).toISOString().slice(0, 16);
          setFormData({
            title: emissionData.title,
            description: emissionData.description,
            type: emissionData.type,
            diffusion_date: formattedDate,
            channelId: emissionData.channelId,
            thumbnail: null,
            video: null,
          });
        } else {
          console.log("Données d'émission non disponibles");
        }
      })
      .catch((error) => {
        console.log("Erreur de récupération des informations de la chaîne:", error);
      });
  }, [id]);
  
  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    setShowFileInput(selectedCategory === "VOD");

    setFormData({...formData, type: selectedCategory})
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    if (name === "diffusion_date") {
      const formattedValue = new Date(value).toISOString().slice(0, 16);
      setFormData({ ...formData, [name]: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleThumbnailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    }
  };

  const handleVideoFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const jwtToken = localStorage.getItem("token");

    const formDataToSend = new FormData();

    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await apiInstanceAws.put("/emission/update", formDataToSend, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.status === 200) {
        console.log("Mise à jour d'émission réussie");
      } else {
        console.log("Échec de la mise à jour de l'émission");
        navigate(`/dashboard/edit-emission/${id}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      navigate("/dashboard/emission");
    }
  };

  return (
    <Layout>
    <LayoutDashboard>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
        <Hourglass
          visible={true}
          height="80"
          width="80"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={['#306cce', '#72a1ed']}
        />
      </div>
      )}
      <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6 relative z-0">
        <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
        <label className="block md:col-span-2">
              <Label>Titre de l'Emission *</Label>

              <Input 
                className="mt-1" 
                type="text" 
                name="title" 
                onChange={handleInputChange} 
                value={formData.title} 
                required
              />
            </label>
            <label className="block md:col-span-2">
              <Label>Description</Label>

              <Textarea 
              className="mt-1" 
              rows={4} 
              name="description"
              onChange={handleInputChange} 
              value={formData.description}
              />
              <p className="mt-1 text-sm text-neutral-500">
               Bref description de l'émission
              </p>
            </label>
            <label className="block md:col-span-1">
              <Label>Catégorie</Label>

              <Select className="mt-1" name="category" onChange={handleCategoryChange}>
                <option value="-1">Type d'Emission</option>
                <option value="LIVE">LIVE</option>
                <option value="VOD">VOD</option>
              </Select>
            </label>
            <label className="block md:col-span-1">
              <Label>Date et heure de Diffusion</Label>
              <Input 
                className="mt-1" 
                type="datetime-local" 
                name="diffusion_date"
                onChange={handleInputChange}
                value={formData.diffusion_date}
                />
            </label>
          {formData.type === "VOD" && (
            <div className="block md:col-span-1">
              <Label>Fichier Miniature</Label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <img
                    src={formData.thumbnail ? URL.createObjectURL(formData.thumbnail) : ''}
                    alt="Miniature"
                    className="mx-auto h-12 w-12"
                  />
                  <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                    <label
                      htmlFor="file-upload-thumbnail"
                      className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Upload a file</span>
                      <input
                        className="sr-only"
                        id="file-upload-thumbnail"
                        name="thumbnail"
                        type="file"
                        onChange={handleThumbnailChange}
                        accept=".png, .jpg"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-neutral-500">PNG, JPG, GIF up to 2MB</p>
                </div>
              </div>
            </div>
          )}
          {formData.type === "VOD" && (
            <div className="block md:col-span-1">
              <Label>Fichier vidéo</Label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <video controls width="400">
                    <source src={formData.video ? URL.createObjectURL(formData.video) : ''} type="video/mp4" />
                    Votre navigateur ne supporte pas la vidéo.
                  </video>
                  <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                    <label
                      htmlFor="file-upload-video"
                      className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                    >
                      <span>Fichier Vidéo</span>
                      <input
                        id="file-upload-video"
                        className="sr-only"
                        name="video"
                        onChange={handleVideoFilesChange}
                        type="file"
                      />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs text-neutral-500">PNG, JPG, GIF up to 2MB</p>
                </div>
              </div>
            </div>
          )}
          {formData.type === "LIVE" && (
            <div className="block md:col-span-1">
            <Label>Fichier Miniature</Label>

            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
              <div className="space-y-1 text-center">
                {/* <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg> */}
                <img
                  src={formData.thumbnail ? URL.createObjectURL(formData.thumbnail) : liveRadioImage}
                  alt="Miniature"
                  className="mx-auto h-12 w-12"
                />


                <div className="flex flex-col sm:flex-row text-sm text-neutral-6000">
                  <label
                    htmlFor="file-upload-thumbnail"
                    className="relative cursor-pointer rounded-md font-medium text-primary-6000 hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                  >
                    <span>Upload a file</span>
                    <input
                      className="sr-only"
                      id="file-upload-thumbnail"
                      name="thumbnail"
                      type="file"
                      onChange={handleThumbnailChange}
                      accept=".png, .jpg"
                    />
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-neutral-500">
                  PNG, JPG, GIF up to 2MB
                </p>
              </div>
            </div>
          </div>
          )}
          <ButtonPrimary className="md:col-span-2" type="submit" disabled={loading}>
            {loading ? "En cours..." : "CREER"}
          </ButtonPrimary>
        </form>
      </div>
    </LayoutDashboard>
  </Layout>

  );
};

export default DashboardEditVODEmission;
