// store.ts
import { create } from 'zustand';
import axios from 'axios';
import { RadioType } from 'data/types';
import apiInstanceAws from 'app/api/apiServicesAfricaWebStream';

type StoreState = {
  data: RadioType[];
  fetchData: () => void;
  deleteChannel: (channelId: string) => void;
};

export const useStore = create<StoreState>((set) => ({
  data: [],
  fetchData: async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await apiInstanceAws.get<{ allchannels: RadioType[] }>('/channel/list', {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // Ajoutez d'autres en-têtes d'authentification si nécessaire
        },
      });
      const channels = response.data.allchannels;
      set({ data: channels });
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error);
    }
  },
  deleteChannel: async (channelId: string) => {
    try {
      const token = localStorage.getItem('token');
      await apiInstanceAws.delete(`/channel/delete/${channelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // Ajoutez d'autres en-têtes d'authentification si nécessaire
        },
      });
      set((state) => ({
        data: state.data.filter((channel) => channel.id !== channelId),
      }));
    } catch (error) {
      console.error('Erreur lors de la suppression du canal :', error);
    }
  },
}));

export const useEmissionStor = create<StoreState>((set) => ({
  data: [],
  fetchData: async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await apiInstanceAws.get<{ allchannels: RadioType[] }>('/channel/list', {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // Ajoutez d'autres en-têtes d'authentification si nécessaire
        },
      });
      const channels = response.data.allchannels;
      set({ data: channels });
    } catch (error) {
      console.error('Erreur lors de la récupération des données :', error);
    }
  },
  deleteChannel: async (channelId: string) => {
    try {
      const token = localStorage.getItem('token');
      await apiInstanceAws.delete(`/delete/${channelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // Ajoutez d'autres en-têtes d'authentification si nécessaire
        },
      });
      set((state) => ({
        data: state.data.filter((channel) => channel.id !== channelId),
      }));
    } catch (error) {
      console.error('Erreur lors de la suppression du canal :', error);
    }
  },
}));
