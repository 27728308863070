import React, { FC } from "react";
import { EmissionTypeInfos, PostDataType, RadioType } from "../../data/types";
import GallerySlider from "./GallerySlider";
import MediaVideo from "./MediaVideo";
import PostTypeFeaturedIcon from "../PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import MediaAudio from "./MediaAudio";
import Image from "../Image";
import Link from "../Link";

export interface RadioFeaturedMediaProps {
  className?: string;
  radio: RadioType;
  isHover?: boolean;
}

const RadioFeaturedMedia: FC<RadioFeaturedMediaProps> = ({
  className = "w-full h-full",
  radio,
  isHover = false,
}) => {
  const { id, radio_logo } = radio;
  const postType = "video";

  const isPostMedia = () => postType === "video" ;

  return (
    <div className={`nc-RadioFeaturedMedia relative ${className}`}>
        <Image
          alt="Thumnail"
          fill
          className="object-cover"
          src={radio_logo}
          sizes="(max-width: 600px) 480px, 800px"
        />
      
        <Link
          href={"/search/"+id}
          className={`block absolute inset-0 ${
            !postType
              ? "bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
              : ""
          }`}
        />
    </div>
  );
};

export default RadioFeaturedMedia;
