import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import { useParams } from "react-router-dom";
import { useStore } from "../store";
import { parseInt } from "lodash";
import { RadioType } from "data/types";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";
import NcImage from "components/NcImage/NcImage";

// const data = [
//   { name: "Subcription Name", content: " Premium" },
//   { name: "Package & billing details", content: " $222.00" },
//   { name: "Remaining post", content: " 18" },
//   { name: "Expire date", content: " October 20, 2021" },
// ];

const DashboardSubcription = () => {
  const { id } = useParams<{ id: string }>();
  const [infoChaine, setInfoChaine] = useState<RadioType | null>(null);

  useEffect(() => {
    apiInstanceAws.get<{chaineInfo : RadioType | null}>(`/channel/${id}`)
      .then((response)=>{
        const infoChaine = response.data.chaineInfo;
        setInfoChaine(infoChaine)
      })
      .catch((error)=>{
        console.log("Erreur de récuperation des information de la chaine:", error)
      })
  }, []);
  // if (id) {  
  //   console.log(id)
  //   console.log(data)
  //    selectedItem = data.find((item)=> item.id ===parseInt(id))
  //   }
  //   console.log("********************")
  //   console.log(selectedItem)

  console.log(infoChaine)
  return (
    <Layout>
       <LayoutDashboard>
        <div className="bg-white dark:bg-neutral-900 dark:border dark:border-neutral-800 shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-neutral-900 dark:text-neutral-200">
             Information sur la radio : {infoChaine? infoChaine.radio_name : ""}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-neutral-500 dark:text-neutral-400">
              {`You've subscribed to the following package`}
            </p>
          </div>
          <div className="border-t border-neutral-200 dark:border-neutral-900">
            <dl>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                    LOGO DE LA CHAINE 
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                    <NcImage
                      containerClassName="flex-shrink-0 h-12 w-12 rounded-lg relative z-0 overflow-hidden lg:h-14 lg:w-14"
                      src={infoChaine? infoChaine.radio_logo : ""}
                      fill
                      sizes="80px"
                      alt="post"
                    />
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                    NOM DE LA CHAINE
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                  {infoChaine? infoChaine.radio_name : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                   EMAIL
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                    {infoChaine? infoChaine.radio_email : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                    TELEPHONE
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                  {infoChaine? infoChaine.radio_phone : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                    VILLE
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                  {infoChaine? infoChaine.radio_city : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                    ADESSE IP DU SERVEUR
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                  {infoChaine? infoChaine.ip_server_adress : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                    POINT DE MONTAGE
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                  {infoChaine? infoChaine.server_entrypoint : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                    URL DE DIFFUSION
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                  {infoChaine? infoChaine.server_output : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                   NOM DU SERVEUR DE DIFFUSION
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                   {infoChaine? infoChaine.stream_server_name : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                   NOM D'UTILISATEUR
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                   {infoChaine? infoChaine.auth_username : ""}
                  </dd>
                </div>
                <div className= "px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-neutral-500 dark:text-neutral-300 bg-white dark:bg-neutral-900">
                    MOT DE PASSE D'UTILISATEUR
                  </dt>
                  <dd className="mt-1 text-sm text-neutral-900 dark:text-neutral-200 font-medium sm:mt-0 sm:col-span-2 bg-neutral-50 dark:bg-neutral-800">
                    {infoChaine? infoChaine.auth_password : ""}
                  </dd>
                </div>
            </dl>
          </div>
        </div>
      </LayoutDashboard> 
    </Layout>
  );
};

export default DashboardSubcription;
