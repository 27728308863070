import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Select from "components/Select/Select"
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import apiInstanceAwr from "app/api/apiServicesAwr";
import { RadioType } from "data/types";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";
import { useNavigate, useParams } from "react-router-dom";

const DashboardEditProfile = () => {
const {id} = useParams<{id : string}>();
const navigate = useNavigate();
const [infoChaine, setInfoChaine] = useState<RadioType | null>(null);
const [formaData, setFormData]= useState<RadioType | {} | any>({
  radio_id : '',
  radio_name: '',
  radio_logo: '',
  radio_phone: '',
  radio_email: '',
  radio_city: '',
  ip_server_adress: '',
  stream_server_name: '',
  host_port: '',
  server_entrypoint: '',
  auth_username: '',
  auth_password: '',
  server_output: '',
})
useEffect(() => {
  apiInstanceAws.get<{chaineInfo : RadioType | null}>(`/channel/${id}`)
    .then((response)=>{
      const infoChaine = response.data.chaineInfo;
      setInfoChaine(infoChaine);
      setFormData({
        radio_id : infoChaine? infoChaine.radio_id : "",
        radio_name:  infoChaine? infoChaine.radio_name : "",
        radio_logo:  infoChaine? infoChaine.radio_logo : "",
        radio_phone:  infoChaine? infoChaine.radio_phone : "",
        radio_email:  infoChaine? infoChaine.radio_email : "",
        radio_city:  infoChaine? infoChaine.radio_city : "",
        ip_server_adress:  infoChaine? infoChaine.ip_server_adress : "",
        stream_server_name:  infoChaine? infoChaine.stream_server_name : "",
        host_port:  infoChaine? infoChaine.host_port : "",
        server_entrypoint:  infoChaine? infoChaine.server_entrypoint : "",
        auth_username:  infoChaine? infoChaine.auth_username : "",
        auth_password:  infoChaine? infoChaine.auth_password : "",
        server_output:  infoChaine? infoChaine.server_output : "",
      })
    })
    .catch((error)=>{
      console.log("Erreur de récuperation des information de la chaine:", error)
    })
}, []);

    const handleFormaChange = (event : React.ChangeEvent<HTMLInputElement>)=>{
      const { name, value} = event.target;
      setFormData({
        ...formaData,
        [name]: value,
      })
    };

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      //console.log('Données du formulaire soumises :', formaData);
      
      apiInstanceAws.put<RadioType[]>(`/channel/update/${id}`, formaData)
      .then((response) => {
        console.log('Données de la chaîne mises à jour avec succès :', response.data);
        navigate('/dashboard/posts');
      })
      .catch((error) => {
        console.log('Erreur lors de l\'envoi du formulaire :', error);
      });
    };
  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-sm md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          <Label>Modification d'une Chaîne</Label>
          {/* <div>
            <Select className="my-10" value={selectedOption ? selectedOption.id.toString() : ''} onChange={handleChange}>
              <option value="">Sélectionnez la radio</option>
              {options.map((option) => (
                <option key={option.id.toString()} value={option.id.toString()}>
                  {option.name}
                </option>
              ))}
            </Select>
          </div> */}
          <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <Label>Nom de la Chaîne</Label>
              <Input 
                placeholder="Nom de la radio" 
                type="text" 
                className="mt-1" 
                name="radio_name"
                 value={formaData.radio_name}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                disabled
              />
            </label>
            <label className="block">
              <Label>email</Label>
              <Input 
                  placeholder="email" 
                  type="text" 
                  className="mt-1"
                  name="radio_email"
                   value={formaData.radio_email}
                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                  disabled 
                />
            </label>
            <label className="block">
              <Label>Phone</Label>
              <Input 
                placeholder="phone" 
                type="text" 
                className="mt-1"
                name="radio_phone"
                 value={formaData.radio_phone}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                disabled 
                />
            </label>
            <label className="block">
              <Label>ville</Label>
              <Input 
                placeholder="ville" 
                type="text" 
                className="mt-1"
                name="radio_city" 
                 value={formaData.radio_city}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                disabled
                />
            </label>
            <label className="block">
              <Label>Adresse IP du serveur</Label>
              <Input 
                placeholder="adresse IP du serveur" 
                type="text" 
                className="mt-1" 
                name="ip_server_adress"
                 value={formaData.ip_server_adress}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>Port du serveur</Label>
              <Input 
                placeholder="Port du serveur" 
                type="text" 
                className="mt-1" 
                name="host_port"
                 value={formaData.host_port}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>point de montage</Label>
              <Input 
                placeholder="point de montage" 
                type="text" 
                className="mt-1" 
                name="server_entrypoint"
                 value={formaData.server_entrypoint}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>Url de diffusion</Label>
              <Input 
                placeholder="url de diffusion" 
                type="text" 
                className="mt-1"
                name="server_output"
                 value={formaData.server_output}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>Nom d'utilisateur</Label>
              <Input 
                placeholder="nom d'utilisateur" 
                type="text" 
                className="mt-1"
                name="auth_username"
                 value={formaData.auth_username} 
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
                />
            </label>
            <label className="block">
              <Label>Mot de passe</Label>
              <Input 
                type="text" 
                className="mt-1"
                name="auth_password"
                 value={formaData.auth_password}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)} 
                />
            </label>
            <label className="block md:col-span-2">
              <Label> Nom du serveur</Label>
              <Input
                type="text"
                placeholder="nom du serveur"
                className="mt-1"
                name="stream_server_name"
                 value={formaData.stream_server_name}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
              />
            </label>
            <ButtonPrimary className="md:col-span-2" type="submit">
              Créer la Chaîne
            </ButtonPrimary>
          </form>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardEditProfile;
