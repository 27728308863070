import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import SimplePeer from "simple-peer";
import io, { Socket } from "socket.io-client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlayCircle, FaPause } from "react-icons/fa";
import NcPlayIcon from "components/NcPlayIcon/NcPlayIcon";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import isSafariBrowser from "utils/isSafariBrowser";
import Image from "components/Image";
import { DEMO_CATEGORIES } from "data/taxonomies";
import SingleTitle from "app/(singles)/SingleTitle";
import SingleMetaAction2 from "app/(singles)/SingleMetaAction2";
import Layout from "./layout";
import { useParams } from "react-router-dom";
import { EmissionTypeInfos, RadioType } from "data/types";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";
import liveImage from "images/live.png";
import Button from "components/Button/Button";

const token = localStorage.getItem("token");
const LiveDetail = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDevice, setSelectedDevice] = useState<string | undefined>(undefined);
  const [liveinfo, setLiveInfo] = useState<RadioType | null>(null);
  const [socket, setSocket] = useState<Socket | null>(null);
  const { id } = useParams<{ id: string }>();
  const cle_youtube = localStorage.getItem('cleFlux')
  const url_youtube = localStorage.getItem('urlFlux')
  const url_youtube_stream = `${url_youtube}/${cle_youtube}`;
  console.log(url_youtube_stream)
  const startStream = async () => {
    console.log("Requesting permission to access webcam and microphone...");

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: selectedDevice ? { exact: selectedDevice } : undefined },
        audio: true,
      });
      console.log("Webcam and microphone access granted.");

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }

      // Si une connexion Socket.IO existe déjà, la déconnecter
      if (socket) {
        socket.disconnect();
      }

      // Initialise une nouvelle connexion Socket.IO
      //const newSocket = io("wss://api.deviitechsarl.net", { query: { token: token } });
      //const newSocket = io("http://localhost:8443", { query: { token: token } });
      const newSocket = io("wss://api.deviitechsarl.net", { 
         path: "/awrstream/",
         withCredentials: true,
         transports: ['websocket', 'polling'],
         query: { token: token, url_youtube_stream:url_youtube_stream },
      });
      
      setSocket(newSocket);

      // Spécifiez des options d'enregistrement, comme le format WebM
      const options = {
        mimeType: 'video/webm'
      };
      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          console.log("Sending stream data to backend...");
          newSocket.emit("stream-data", event.data);
        }
      };

      mediaRecorder.onstop = () => {
        newSocket.emit("stop-stream");
        console.log("MediaRecorder stopped.");
      };

      mediaRecorder.start(1000);
      console.log("MediaRecorder started.");
      toast.success('Live lancé !'); // Notification de succès
    } catch (error) {
      console.error("Error accessing webcam/microphone:", error);
      setIsStreaming(false);
      toast.error('Erreur lors de l\'accès à la webcam/microphone.'); // Notification d'erreur
    }
  };

  useEffect(() => {
    // Obtenir la liste des périphériques audio et vidéo disponibles
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const videoDevices = devices.filter((device) => device.kind === 'videoinput');
      setDevices(videoDevices);
      apiInstanceAws.get<{emission : RadioType | null}>(`/emission/${id}`)
        .then((response)=>{
          const liveinfo = response.data.emission;
          console.log(liveinfo);
          setLiveInfo(liveinfo);
        })
        .catch((error)=>{
          console.log("Erreur de récuperation des information de la chaine:", error)
        })
    });

    // Cleanup function to stop stream and disconnect socket on component unmount
    return () => {
      stopStream();
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  const handleDeviceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedDeviceId = event.target.value;
    setSelectedDevice(selectedDeviceId);
  };

  const stopStream = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }

    if (socket) {
      socket.emit("stop-stream");
    }
    setIsStreaming(false);
    toast.info('Live arrêté.'); // Notification d'information
    console.log("Streaming stopped.");
  };

  const renderMainVideo = () => {
    return (
      <div className="">
        {isStreaming ? (
          <video
            width="100%"
            height="100%"
            ref={videoRef}
            autoPlay
            muted
            className="absolute inset-0"
          ></video>
        ) : (
          <div
            className="absolute inset-0 z-10 cursor-pointer "
            onClick={() => setIsStreaming(true)}
          >
            {/* Ajoutez votre image de prévisualisation ici */}
            <Image
              src={liveImage}
              className="object-cover"
              sizes="(max-width: 1024px) 100vw, 50vw"
              alt="single"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <NcPlayIcon />
            </div>
          </div>
        )}

        {devices.length > 0 && (
          <div className="video-options absolute left-4 top-4">
            <select
              className="custom-select"
              onChange={handleDeviceChange}
              value={selectedDevice || ''}
            >
              <option value="">Select camera</option>
              {devices.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </select>
          </div>
        )}

        <img className="screenshot-image d-none" alt="" />

        <div className="controls absolute right-4 top-4 flex">
          <button
            className="btn btn-danger pause w-10 h-10 rounded-full flex items-center justify-center mr-2 focus:outline-none"
            title="Pause"
            onClick={stopStream}
          >
            <FaPause className="text-white" />
          </button>
          <button
            className="btn btn-info play w-10 h-10 rounded-full flex items-center justify-center mr-2 focus:outline-none"
            title="Play"
            onClick={startStream}
          >
            <FaPlayCircle className="text-white" />
          </button>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className={`nc-SingleHeader `}>
        <div className="space-y-5 dark text-neutral-100">
          <SingleTitle
            mainClass="text-neutral-900 font-semibold text-3xl md:!leading-[120%] dark:text-neutral-100"
            title={`${liveinfo?.title}`} 
          />

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col space-y-5">
            <SingleMetaAction2 />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <header className="container relative py-14 lg:py-20 flex flex-col lg:flex-row lg:items-center">
        <div className="nc-PageSingleVideo__headerWrap absolute inset-y-0 transform translate-x-1/2 right-1/2 w-screen lg:translate-x-0 lg:w-[calc(100vw/2)] bg-neutral-900 dark:bg-black dark:bg-opacity-50 lg:rounded-r-[40px]"></div>
        <div className="pb-10 lg:pb-0 lg:pr-10 relative">{renderHeader()}</div>
        <div className="relative lg:w-8/12 flex-shrink-0">
          <div className="aspect-w-16 aspect-h-16 sm:aspect-h-9 border-4 border-neutral-300 dark:border-neutral-800 shadow-2xl bg-neutral-800 rounded-3xl overflow-hidden z-0">
            {renderMainVideo()}
          </div>
        </div>
      </header>
      <ToastContainer />
    </Layout>
  );
};

export default LiveDetail;
