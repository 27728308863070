import React, { FC } from "react";
import { EmissionTypeInfos, PostDataType } from "data/types";
import GallerySlider from "./GallerySlider";
import MediaVideo from "./MediaVideo";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import MediaAudio from "./MediaAudio";
import Link from "components/Link";
import Image from "components/Image";

export interface ProgramPostFeaturedMediaLiveProps {
  className?: string;
  post: EmissionTypeInfos;
  isHover?: boolean;
}

const ProgramPostFeaturedMediaLive: FC<ProgramPostFeaturedMediaLiveProps> = ({
  className = "w-full h-full",
  post,
  isHover = false,
}) => {
  const { id, files, thumbnail, description } = post;
  const postType = "video";

  const isPostMedia = () => postType === "video" ;

  const imagePath = `http://localhost:8000/${thumbnail}`;
  const videoPath = `http://localhost:8000/${files[0].path}`;
  const formattedImagePath = imagePath.replace(/\\/g, '/');
  const formattedVideoPath = videoPath.replace(/\\/g, '/');
  const renderContent = () => {
    
    // VIDEO
    if (postType === "video" && !!files?.[0]?.path && isHover) {
      return <MediaVideo isHover videoUrl={formattedVideoPath} />;
    }

    // ICON
    return isPostMedia() ? (
      <span className="absolute inset-0 flex items-center justify-center ">
        <PostTypeFeaturedIcon
          className="hover:scale-105 transform cursor-pointer transition-transform"
          postType={postType}
        />
      </span>
    ) : null;
  };

  return (
    <div className={`nc-PostFeaturedMedia relative ${className}`}>
        <Image
          alt="Thumnail"
          fill
          className="object-cover"
          src={formattedImagePath}
          sizes="(max-width: 600px) 480px, 800px"
        />
      
      {renderContent()}
        <Link
          href={"/single-video/demo-slug/"+id}
          className={`block absolute inset-0 ${
            !postType
              ? "bg-black/20 transition-opacity opacity-0 group-hover:opacity-100"
              : ""
          }`}
        />
    </div>
  );
};

export default ProgramPostFeaturedMediaLive;
