import React, { useEffect, useState } from "react";
import { Hourglass } from "react-loader-spinner";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Image from "components/Image";
import Layout from "../layout";
import { AuthDataType } from "data/types";
import apiInstanceAws from "app/api/apiServicesAfricaWebStream";
import Cookies from "js-cookie";
//import jwt from 'jsonwebtoken'
import jwtDecode, { JwtPayload } from "jwt-decode";
import { useNavigate } from "react-router-dom";
const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin = () => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [authData, setAuData] = useState<AuthDataType | {} | any>({
    email: '',
    password : ''
  });
  const [error, setError] = useState<string>("");
  const navigate = useNavigate()

  const handleFormaChange = (event : React.ChangeEvent<HTMLInputElement>)=>{
    const { name, value} = event.target;
    setAuData({
      ...authData,
      [name]: value,
    })
  };
const handleSubmit = (event: React.FormEvent) => {
  event.preventDefault();
  setLoading(true);
  const jwtToken = localStorage.getItem("token");
  console.log("*********************", jwtToken)
  apiInstanceAws.post<AuthDataType>('/auth/user/login', authData, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${jwtToken}`
      // Ajoutez d'autres en-têtes si nécessaire, comme l'en-tête d'autorisation
    }
  })
  .then((response) => {
    const {token} = response.data
    localStorage.setItem('token', token);
    console.log('Recuperation des données : ',response.data)
    const decoded = jwtDecode<{role :JwtPayload | string}>(token);
    // if (decoded) {
    //   const userRole: JwtPayload | string = decoded.role;

    //   if (userRole === 'ADMIN') {
    //     window.location.href = '/dashboard/posts';
    //     //navigate('/dashboard/posts');
    //     console.log('Authentification réussie:',  decoded);
    //   } else if (userRole === 'SPECTATOR') {
    //     window.location.href = '/home-4';
    //    // navigate('/home-4');
    //     console.log('Authentification réussie:',  decoded);
    //   } else if (userRole === 'ANIMATOR') {
    //     window.location.href = '/dashboard/submit-post';
    //    // navigate('/dashboard/submit-post');
    //     console.log('Authentification réussie:',  decoded);
    //   } else {
    //     window.location.href = '/archive/the-demo-archive-slug';
    //    // navigate('/archive/the-demo-archive-slug');
    //     console.log('Authentification réussie:',  decoded);
    //   }
    // }
    if (decoded) {
      const userRole: JwtPayload | string = decoded.role;

      // Redirection en fonction du rôle de l'utilisateur
      switch (userRole) {
        case 'ADMIN':
          window.location.href = '/dashboard/posts';
          break;
        case 'SPECTATOR':
          window.location.href = '/';
          break;
        case 'ANIMATOR':
          window.location.href = '/dashboard/submit-post';
          break;
        default:
          window.location.href = '/archive/the-demo-archive-slug';
      }
    }
    //console.log('Authentification réussie:',  decoded);
  })
  .catch((error) => {
    console.log('Erreur lors de l\'envoi du formulaire :', error);
    setError("Identifiant ou mot de passe invalide.");
  });
};

  return (
    <Layout>
      {loading && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={['#306cce', '#72a1ed']}
            />
          </div>
        )}
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
        <Heading2>Connexion</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Bienvenue sur la plateforme de AfricaWebStream
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/* <div className="grid gap-3">
          {loginSocials.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
            >
              <Image
                className="flex-shrink-0"
                src={item.icon}
                alt={item.name}
              />
              <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                {item.name}
              </h3>
            </a>
          ))}
        </div> */}
        {/* OR */}
        {/* <div className="relative text-center">
          <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
            OR
          </span>
          <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
        </div> */}
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Email 
            </span>
            <Input
              type="email"
              placeholder="example@example.com"
              className="mt-1"
              name="email"
              required  
              defaultValue={authData.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)}
            />
          </label>
          <label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              Mot de passe
              {/* <NcLink href="/forgot-pass" className="text-sm underline">
                Forgot password?
              </NcLink> */}
            </span>
            <Input 
              type="password"  
              className="mt-1"
              name="password"
              required
              defaultValue={authData.password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFormaChange(event)} 
              />
          </label>
          {/* Affichage du message d'erreur */}
          {error && <div className="text-red-500">{error}</div>}
          {/* Bouton de soumission du formulaire */}
          <ButtonPrimary type="submit">
              {loading ? "En cours..." : "Connexion"}
          </ButtonPrimary>
        </form>

        {/* ==== */}
        {/* <span className="block text-center text-neutral-700 dark:text-neutral-300">
          New user? {` `}
          <NcLink href="/signup">Create an account</NcLink>
        </span> */}
      </div>
    </Layout>
  );
};

export default PageLogin;
